import Splide from '@splidejs/splide';
import generateTabs from '../components/generateTabs';
import '../../styles/pages/videos.scss';
import '../components/tv-hosts'

generateTabs('sidebar-last', { isLoadMore: false });

// const heroesBgs = document.querySelectorAll('.heroes__image-bg');
const heroesTabsBtns = document.querySelectorAll('.heroes-tabs .heroes-tabs__tab_btn');
const heroesImages = document.querySelectorAll('.tv-hosts__images .tv-hosts__image');

const handleMouseOver = (idx) => {
  heroesImages[idx].classList.add('tv-hosts__image_active');
  heroesTabsBtns[idx].classList.add('heroes-tabs__tab_btn_active');
  heroesTabsBtns[idx].parentElement.classList.add('splide__hover');
  heroesTabsBtns[idx].parentElement.parentElement.classList.add('splide__hover');
}

const handleMouseOut = (idx) => {
  heroesImages[idx].classList.remove('tv-hosts__image_active');
  heroesTabsBtns[idx].classList.remove('heroes-tabs__tab_btn_active');
  heroesTabsBtns[idx].parentElement.classList.remove('splide__hover');
  heroesTabsBtns[idx].parentElement.parentElement.classList.remove('splide__hover');
}


heroesImages.forEach((itm, idx) => {
  itm.addEventListener('mouseover', () => handleMouseOver(idx))
  itm.addEventListener('mouseout', () => handleMouseOut(idx))
})

heroesTabsBtns.forEach((itm, idx) => {
  itm.addEventListener('mouseover', () => handleMouseOver(idx))
  itm.addEventListener('mouseout', () => handleMouseOut(idx))
})



const allVideoSliders = document.querySelectorAll('.splide-video-slider');

allVideoSliders.forEach((itm) => {
  const splide = new Splide(itm, {
    type: 'loop',
    perPage: 4,
    perMove: 1,
    pagination: false,
    gap: 32,
    flickPower: 100,
    breakpoints: {
      768: {
        perPage: 2,
      },
      1024: {
        perPage: 3,
        gap: 24,
      },
    }
  });

  splide.mount();
})

document.querySelector('.sidebar-last .load-more').addEventListener('click', function (e) {
  let active_tab = e.target.closest('.sidebar-last').querySelector('.tabs__item_active').dataset.tab || 'all';
  let tab_content = e.target.closest('.sidebar-last').querySelector(`[data-tabdata=${active_tab}]`);
  load_posts('/load-posts/', tab_content, 'list', tab_content.dataset.page, active_tab);
});
